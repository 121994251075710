import React from 'react';
import Layout from '../components/Layout';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

const Gallery = () => {
  const { t } = useTranslation();

  const data = useStaticQuery(graphql`
    query {
      images: allFile(
        filter: {
          extension: { regex: "/(webp)/" }
          absolutePath: { regex: "/src/images/houses/" }
        }
      ) {
        edges {
          node {
            relativePath
            extension
            publicURL
            childImageSharp {
              gatsbyImageData(width: 600)
            }
          }
        }
      }
    }
  `);
  return (
    <Layout fullMenu={true}>
      <section id="wrapper">
        <header>
          <div className="inner">
            <h2>{t('gallery')}</h2>
            <p>{t('ourOffer')}</p>
          </div>
        </header>

        <div className="wrapper">
          <div className="inner">
            <section className="features">
              {data.images.edges.map((edge, key) => (
                <React.Fragment key={key}>
                  <article>
                    <GatsbyImage
                      image={edge.node.childImageSharp.gatsbyImageData}
                      fadeIn={true}
                      durationFadeIn={1000}
                    />
                  </article>
                </React.Fragment>
              ))}
            </section>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Gallery;
